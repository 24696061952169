.home-main {
  min-height: calc(100vh - 213px);
  .home-wrap {
    position: relative;
    margin-top: 20px;
    padding-top: 80px;
    padding-bottom: 254px;
    @media #{$sm-desktop} {
      margin-top: 50px;
      padding-top: 70px;
      padding-bottom: 174px;
    }
    @media #{$md-mobile} {
      margin-top: 25px;
      padding-top: 60px;
      padding-bottom: 154px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-image: url(../assets/images/home-back.svg);
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
    }
    .box-main {
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      max-width: 620px;
      @media #{$sm-mobile} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      a {
        z-index: 1;
        .inbox {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 10px;
          background-color: $white;
          padding: 50px 50px 60px 50px;
          text-transform: uppercase;
          font-size: 18px;
          margin-top: 40px;
          cursor: pointer;
          transition: 0.4s ease;
          @media #{$sm-desktop} {
            padding: 40px 40px 50px 40px;
            font-size: 16px;
          }
          //@media #{$md-tablet} {
          //width: 47%;
          //}
          @media #{$md-mobile} {
            font-size: 15px;
          }
          @media #{$sm-mobile} {
            margin-top: 30px;
            width: 95%;
            max-width: 255px;
          }
          &:hover {
            transform: scale(1.02);
          }
          figure {
            min-height: 104px;
            margin-bottom: 15px;
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }
  }
}
