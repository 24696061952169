.form-body {
  height: 100vh;

  .row {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    height: 100%;

    .form-holder {
      width: 100%;
      margin-left: 0;

      .form-content {
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px;
        min-height: 100%;

        .form-items {
          max-width: 340px;
          text-align: center;
          display: inline-block;
          width: 100%;
          transition: all 0.4s ease;

          .website-logo-inside {
            margin-bottom: 70px;
          }

          .page-links {
            margin-bottom: 34px;

            button {
              &:nth-child(1),
              &:nth-child(2) {
                margin-right: 20px;
              }

              position: relative;
              border: none;
              padding-bottom: 10px;
              border-bottom: 2px solid $dark2;
              background-color: transparent;
              display: inline-block;
              text-decoration: none;
              font-size: 15px;
              transition: all 0.3s ease;

              &:hover {
                border-bottom: 2px solid $black;
              }
            }
            .active {
              border-bottom: 2px solid $black;
            }
          }

          form {
            margin-bottom: 30px;

            .form-button {
              margin-top: 30px;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}
