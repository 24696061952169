/*color variable*/
$gray: #707070;
$red: red;
$lite-color: #f3f3f3;
$white: #fff;
$lite-color2: #dcdcdc;
$black: #000;
$lite-color3: #f9f9f9;
$border-color: #ededf1;
$dark2: #c5c5c5;

/*fonts variable*/
$primary_fonts: "Circular Std";
$secondary_font: "Roboto", sans-serif;

/*media query variable*/
$lg-desktop: "only screen and (min-width: 1420px)";
$rg-desktop: "only screen and (max-width: 1419px)";
$desktop: "only screen and (min-width: 1200px)";
$sm-desktop: "only screen and (max-width: 1199px)";
$md-tablet: "only screen and (max-width: 991px)";
$md-mobile: "only screen and (max-width: 767px)";
$sm-mobile: "only screen and (max-width: 575px)";
