.dashboard {
  position: relative;
  top: 0;
  height: 100vh;

  .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: 50%;

    &::before {
      opacity: 0.33;
      background: #000;
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .sidebar-background {
      background-image: url("https://demos.creative-tim.com/light-bootstrap-dashboard-react/static/media/sidebar-3.9992d0ce.jpg");
      position: absolute;
      z-index: 1;
      height: 100vh;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: 50%;
    }

    &::after {
      content: "";
      background: linear-gradient(180deg, #282828 0, rgba(17, 17, 17, 0.7));
      background-size: 150% 150%;
      opacity: 0.77;
      z-index: 3;
      display: block;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
    }

    .sidebar-wrapper {
      position: relative;
      max-height: calc(100vh - 75px);
      min-height: 100%;
      overflow: auto;
      width: 260px;
      z-index: 4;
      padding-bottom: 100px;

      .logo {
        padding: 10px 15px;
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);

        .simple-text {
          text-transform: uppercase;
          padding: 5px 0;
          display: block;
          font-size: 18px;
          color: #fff;
          text-align: center;
          font-weight: 400;
          line-height: 30px;
        }
      }

      .nav {
        margin-top: 20px;
        float: none;
        display: block;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        .nav-link {
          color: #fff;
          margin: 5px 15px;
          opacity: 0.86;
          border-radius: 4px;
          display: block;
          padding: 10px 15px;
          text-transform: uppercase;
          line-height: 30px;
          font-size: 12px;
          font-weight: 600;
        }

        .active {
          opacity: 1;
          background: hsla(0, 0%, 100%, 0.23);
        }
      }
    }
  }

  .main-panel,
  .sidebar {
    overflow: auto;
    max-height: 100%;
    height: 100%;
    transition-property: top, bottom;
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: linear, linear;
  }

  .main-panel {
    background: rgba(203, 203, 210, 0.15);
    position: relative;
    float: right;
    width: calc(100% - 260px);
    min-height: 100%;

    .navbar {
      border: 0;
      font-size: 16px;
      border-radius: 0;
      min-height: 50px;
      background-color: hsla(0, 0%, 100%, 0.96);
    }

    .content {
      padding: 30px 15px;
      min-height: calc(100% - 123px);
    }
  }
}
