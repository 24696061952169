header {
  padding: 18px 0px;
  background-color: $white;
  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-info {
    display: flex;
    align-items: center;
    .designation {
      font-size: 16px;
      line-height: 22px;
    }
    figure {
      border: 2px solid $gray;
      border-radius: 100%;
      width: 52px;
      height: 52px;
      overflow: hidden;
      margin-right: 9px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.logo {
  color: $gray;
  font-size: 30px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-family: $secondary_font;
  cursor: pointer;

  @media #{$sm-mobile} {
    font-size: 24px;
  }
}
